import React from 'react';
import { DataTable } from '@tymate/elise/components';
import { useSearchParams } from '@tymate/elise/hooks';
import { Title } from '@tymate/elise/ui';
import { formatSort } from '@tymate/elise/utils';
import { Pagination } from '@tymate/elise/components/New';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getFranchiseWasteDisposals } from 'api/franchises';

const WasteDisposals = () => {
  const { franchiseId } = useParams();
  const [{ page = 1, sort }] = useSearchParams();

  const { data: wasteDisposalsData, isLoading } = useQuery(
    ['franchiseWasteDisposals', franchiseId, sort, page],
    () => {
      return getFranchiseWasteDisposals({
        franchiseId,
        params: {
          'page[number]': page,
          'page[size]': 15,
          sort: formatSort(sort),
        },
      });
    },
  );

  const wasteDisposals = wasteDisposalsData?.data || [];

  const headings = [
    {
      slug: 'name',
      label: 'Nom',
    },
    {
      slug: 'address',
      label: 'Adresse',
      cannotBeReordered: true,
    },
    {
      slug: 'city',
      label: 'Ville',
      cannotBeReordered: true,
    },
    {
      slug: 'zipCode',
      label: 'Code postal',
      cannotBeReordered: true,
    },
    {
      slug: 'siret',
      label: 'Siret',
      cannotBeReordered: true,
    },
    {
      slug: 'actions',
      cannotBeReordered: true,
    },
  ];

  const data = wasteDisposals.map(
    ({ id, name, address, city, zipCode, siret }) => ({
      name: {
        value: name,
      },
      address: { value: address },
      city: { value: city },
      zipCode: { value: zipCode },
      siret: { value: siret },
    }),
  );

  return (
    <>
      <Title>Exutoires</Title>
      <DataTable headings={headings} data={data} loading={isLoading} />
      <Pagination meta={wasteDisposalsData?.headers} />
    </>
  );
};

export default WasteDisposals;
