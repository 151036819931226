import React from 'react';
import { Switch, Route, Redirect, NavLink, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Tabs as RawTabs } from '@tymate/elise/ui/tabs';
import Users from './Users';
import Vehicles from './Vehicles';
import { Container, ButtonReset } from '@tymate/elise/ui';
import UserDetail from '../UserDetail';
import WasteDisposals from './WasteDisposals';

const Tabs = styled(RawTabs)`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Tab = styled.li`
  display: block;
  + li {
    padding-left: ${({ theme }) => theme.spacing()};
  }
`;

const TabNavButton = styled(ButtonReset)`
  display: block;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  appearance: none;
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(0.375)}
    ${({ theme }) => theme.spacing()};
  cursor: pointer;
  width: max-content;
  text-align: center;

  &.active {
    color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.primaryLighten};
  }
`;

const Franchise = () => {
  const { franchiseId } = useParams();

  return (
    <Container variant="main">
      <Tabs>
        <Tab>
          <TabNavButton
            to={`/franchises/${franchiseId}/ressources/collaborateurs`}
            as={NavLink}
          >
            Collaborateurs
          </TabNavButton>
        </Tab>
        <Tab>
          <TabNavButton
            to={`/franchises/${franchiseId}/ressources/vehicules`}
            as={NavLink}
          >
            Véhicules
          </TabNavButton>
        </Tab>
        <Tab>
          <TabNavButton
            to={`/franchises/${franchiseId}/ressources/exutoires`}
            as={NavLink}
          >
            Exutoires
          </TabNavButton>
        </Tab>
      </Tabs>
      <Switch>
        <Route
          path="/franchises/:franchiseId/ressources/collaborateurs"
          component={Users}
        />
        <Route
          path="/franchises/:franchiseId/ressources/vehicules"
          component={Vehicles}
        />
        <Route
          path="/franchises/:franchiseId/ressources/exutoires"
          component={WasteDisposals}
        />
        <Redirect to="/franchises/:franchiseId/ressources/collaborateurs" />
      </Switch>
      <Switch>
        <Route
          path="/franchises/:franchiseId/ressources/collaborateurs/:userId"
          component={UserDetail}
        />
      </Switch>
    </Container>
  );
};

export default Franchise;
