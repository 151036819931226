import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import * as Yup from 'yup';
import useYupResolver from '@tymate/elise/hooks/useYupResolver';
import { Buttons } from '@tymate/elise/ui';
import { Button, ExternalLink } from '@tymate/elise/components';
import TextField from '@tymate/elise/components/Fields/TextField';
import { isNumber } from 'lodash';
import { validationFloat } from '@tymate/elise/utils/validation';
import { ERRORS } from '../../utils';
import { Text } from '../../ui/typography';
import SelectField from '../Fields/SelectField';

const TAX_RATE = [
  { label: '0', value: 0 },
  { label: '5,5', value: 5.5 },
  { label: '20', value: 20 },
];

const Separator = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.separator};
  margin: ${({ theme }) => theme.spacing()} 0;
`;

const Form = styled.form`
  width: 100%;
  height: 100%;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const VALIDATION_SCHEMA = Yup.object().shape({
  downgradingPrice: validationFloat()
    .required(ERRORS.REQUIRED)
    .test('is-hundredth', 'Maximum 2 chiffres après la virgule', value => {
      const [, decimal = []] = value.toString().split('.');

      return decimal?.length <= 2;
    }),
});

const FranchiseParametersForm = ({ franchise, onSubmit }) => {
  const resolver = useYupResolver(VALIDATION_SCHEMA);
  const methods = useForm({
    resolver,
    defaultValues: {
      downgradingPrice: isNumber(franchise?.downgradingPrice)
        ? (franchise?.downgradingPrice / 100).toFixed(2).replace('.', ',')
        : '',
      taxRate: franchise?.taxRate,
    },
  });

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="column" style={{ width: '50%' }}>
          <Separator style={{ marginBottom: '32px' }} />
          <Stack size="full" alignX="space-between" alignY="center">
            <label>Tarif d'un déclassement</label>
            <Stack gutterSize={0.5} style={{ width: '25%' }}>
              <TextField name="downgradingPrice" noMargin={true} />
              <div style={{ marginTop: '12px' }}>€</div>
            </Stack>
          </Stack>
          <Separator style={{ margin: '32px 0' }} />
          <Stack size="full" alignX="space-between" alignY="center">
            <label>
              Taux de la TVA <label>(propre à la franchise)</label>
            </label>
            <Stack gutterSize={0.5} style={{ width: '25%' }}>
              <SelectField name="taxRate" options={TAX_RATE} />
              <div style={{ marginTop: '12px' }}>%</div>
            </Stack>
          </Stack>
          <Separator style={{ margin: '32px 0' }} />
          <Stack size="full" direction="column" gutterSize={1}>
            <Text fontWeight={600}>
              Indice des prix à la consommation (INSEE)
            </Text>
            <ExternalLink url="https://www.insee.fr/fr/statistiques/serie/001763852">
              https://www.insee.fr
            </ExternalLink>
          </Stack>
          <Separator style={{ margin: '32px 0' }} />
        </Stack>
        <Buttons alignment="right" size="full">
          <Button
            type="button"
            onClick={() => methods.reset()}
            variant="phantom"
          >
            Annuler
          </Button>
          <Button
            type="submit"
            variant="primary"
            isLoading={methods.formState.isSubmitting}
          >
            Valider
          </Button>
        </Buttons>
      </Form>
    </FormProvider>
  );
};

export default FranchiseParametersForm;
