import React from 'react';
import { Card, Section, Title } from '@tymate/elise/ui';
import { Stack } from '@tymate/margaret';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import FranchiseParametersForm from '@tymate/elise/components/Forms/FranchiseParametersForm';
import { getFranchise, updateFranchise } from 'api/franchises';
import { Loading } from '@tymate/elise/components';
import { useAppState } from 'hooks';
import { useParams } from 'react-router-dom';

const FranchiseParameters = () => {
  const { notify } = useAppState();
  const { franchiseId } = useParams();
  const queryClient = useQueryClient();

  const { data: franchiseData, isLoading } = useQuery(
    ['franchise', franchiseId],
    () => getFranchise({ franchiseId }),
  );

  const franchise = franchiseData?.data || {};

  const { mutateAsync } = useMutation(updateFranchise, {
    onSuccess: () => {
      queryClient.invalidateQueries(['franchise', franchiseId]);
      notify('Paramètres de franchise mis à jour.');
    },
    onError: () => {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  const handleUpdateMe = async ({ downgradingPrice, ...rest }) => {
    await mutateAsync({
      franchiseId,
      payload: {
        ...franchise,
        ...rest,
        downgradingPrice: parseInt(downgradingPrice * 100),
        axonautApiKeysAttributes: franchise.axonautApiKeys,
      },
    });
  };

  return (
    <Stack marginTop={2}>
      <Section>
        <Card>
          <Stack direction="column" size="full">
            <div>
              <Title>Paramètres</Title>
            </div>
            <FranchiseParametersForm
              franchise={franchise}
              onSubmit={values => handleUpdateMe(values)}
            />
          </Stack>
        </Card>
      </Section>
    </Stack>
  );
};

export default FranchiseParameters;
