import { post, get, destroy, put } from '.';
import { CLIENT_ID } from '../constants';

export const getFranchises = ({ params }) =>
  get(`/v1/admin/franchises`, { params });

export const getFranchise = ({ franchiseId }) =>
  get(`/v1/admin/franchises/${franchiseId}`);

export const createFranchise = payload => post(`/v1/admin/franchises`, payload);

export const getFranchiseUsers = ({ franchiseId, params }) =>
  get(`/v1/admin/franchises/${franchiseId}/users`, { params });

export const getFranchiseUser = ({ franchiseId, userId, params }) =>
  get(`/v1/admin/franchises/${franchiseId}/users/${userId}`, { params });

export const getFranchiseVehicles = ({ franchiseId, params }) =>
  get(`/v1/admin/franchises/${franchiseId}/vehicles`, { params });

export const getFranchiseWasteDisposals = ({ franchiseId, params }) =>
  get(`/v1/admin/franchises/${franchiseId}/waste_disposals`, { params });

export const getWasteDisposal = ({ params, wasteDisposalId }) =>
  get(`/v1/waste_disposals/${wasteDisposalId}`, { params });

export const inviteCollaborator = (franchiseId, payload) =>
  post(`/v1/admin/franchises/${franchiseId}/users`, {
    ...payload,
    clientId: CLIENT_ID,
  });

export const removeUserFranchise = (franchiseId, userId) =>
  destroy(`/v1/admin/franchises/${franchiseId}/users/${userId}`);

export const updateFranchise = ({ franchiseId, payload }) =>
  put(`/v1/admin/franchises/${franchiseId}`, payload);

export const uploadResourcesFranchise = (franchiseId, payload) =>
  post(`v1/admin/franchises/${franchiseId}/resources_csvs`, payload);

export const uploadClientsFranchise = (franchiseId, payload) =>
  post(`v1/admin/franchises/${franchiseId}/collection_points_csvs`, payload);

export const uploadOrdersFranchise = (franchiseId, payload) =>
  post(`v1/admin/franchises/${franchiseId}/orders_csvs`, payload);
